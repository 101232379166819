import React from 'react';

import Layout from '../hoc/Layout';

const Home = (props) => {
  return (
    <Layout title="Statistika">
    </Layout>
  );
}

export default Home;
