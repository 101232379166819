import React, { useState, useEffect, useRef, } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
} from '@mui/material';

import { getDb } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';

const Translations = (props) => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState('');
  const searchTimer = useRef(null);

  const fetchData = async (txt = '') => {
    const db = getDb();
    console.log(txt);
    const res = await db.from('translations')
      .select()
      .ilike('rs', `%${txt}%`);

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
      return;
    }

    setData(res.data);
  }

  useEffect(() => {
    fetchData().catch(err => console.log(err));
  }, []);

  const onChangeHandler = (idx, key, value) => {
    const updatedData = [...data];
    const updatedItem = {
      ...data[idx],
      [key]: value,
    }

    updatedData[idx] = updatedItem;

    setData(updatedData);
  }

  const onSaveHandler = async () => {
    const db = getDb();
    for (const item of data) {
      const res = await db.from('translations')
        .update({
          de: item.de,
          fr: item.fr,
          it: item.it,
        })
        .eq('id', item.id);

      if (res.error) {
        console.log(res.error);
      }
    }
  }

  const onSearchUpdate = (newSearch) => {
    setSearch(newSearch);

    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      fetchData(newSearch);
    }, 500);
  }

  const items = data.map((el, idx) => {
    return (
      <React.Fragment key={el.id}>
        <Grid item xs={3}>
          <Typography>{el.rs}</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Nemacki"
            variant="standard"
            value={el.de}
            onChange={e => onChangeHandler(idx, 'de', e.target.value)} />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Francuski"
            variant="standard"
            value={el.fr}
            onChange={e => onChangeHandler(idx, 'fr', e.target.value)} />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Italijanski"
            variant="standard"
            value={el.it}
            onChange={e => onChangeHandler(idx, 'it', e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment>
    );
  });

  return (
    <Layout title="Prevodi">
      <CCard>
        <div>
          <TextField
            fullWidth
            label="Pretraga"
            variant="standard"
            value={search}
            onChange={e => onSearchUpdate(e.target.value)} />
        </div>
        <br />
        <Grid container spacing={2}>
          {items}
          <Grid item xs={12}>
            <Button variant="contained" onClick={onSaveHandler}>Save</Button>
          </Grid>
        </Grid>
      </CCard>
    </Layout>
  );
}

export default Translations;
