import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CCard = (props) => {
  let back = null;
  if (props.back) {
    back = (
      <>
        <IconButton onClick={props.back}><ArrowBackIosIcon /></IconButton>
      </>
    );
  }

  let title = null;
  if (props.title || props.back) {
    title = (
      <>
        <Typography variant="h5" component="h5">
          {back}
          {props.title}
        </Typography>
        <br />
      </>
    );
  }

  return (
    <Card style={{ ...props.style }}>
      <CardContent>
        {title}
        {props.children}
      </CardContent>
    </Card>
  );
}

export default CCard;
