import { createClient } from "@supabase/supabase-js";

let db = undefined;

window.api.getSupabaseConstants()
  .then(({ SUPABASE_URL, SUPABASE_KEY }) => {
    db = createClient(SUPABASE_URL, SUPABASE_KEY);
  })
  .catch(err => {
    console.log(err);
  });

export const getDb = () => {
  return db;
}
