import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getDb } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';

const initialFrm = [
  {
    id: 'name',
    type: 'text',
    label: 'Naziv - srpski',
    value: '',
    valication: {
    },
  },
  {
    id: 'name_de',
    type: 'text',
    label: 'Naziv - nemacki',
    value: '',
    valication: {
    },
  },
  {
    id: 'name_fr',
    type: 'text',
    label: 'Naziv - francuski',
    value: '',
    valication: {
    },
  },
  {
    id: 'name_it',
    type: 'text',
    label: 'Naziv - talijanski',
    value: '',
    valication: {
    },
  },
  {
    id: 'journal_cell',
    type: 'text',
    label: 'Celija u zurnalu',
    value: '',
    valication: {
    },
  },
]

const CompanyAddDataEdit = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const title = useSearchParams()[0].get('title');

  const isNew = id ? false : true;

  const [frm, setFrm] = useState(initialFrm);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const db = getDb();
    const res = await db.from('company_additional_columns')
      .select()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
      //navigate('/company_add_data');
    }

    if (!res.data || !res.data.length) {
      res.data = [{ name: title }];
    }

    const res2 = await db.from('translations')
      .select()
      .eq('rs', res.data[0].name);

    if (res2.error) {
      console.log(error);
      return;
    }

    const updatedFrm = frm.map(el => {
      let value = res.data[0][el.id];

      if (el.id === 'name_de') {
        value = res2.data[0]['de'];
      } else if (el.id === 'name_fr') {
        value = res2.data[0]['fr'];
      } else if (el.id === 'name_it') {
        value = res2.data[0]['it'];
      }

      return {
        ...el,
        readonly: id < 0 && (el.id === 'name' || el.id === 'journal_cell'),
        value: value,
      }
    });
    console.log(updatedFrm);
    setFrm(updatedFrm);
  }

  useEffect(() => {
    if (id) {
      fetchData().catch(err => console.log(err));
    }
  }, [id]);

  const onSaveHandler = async (data) => {
    const db = getDb();

    const req = data.reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});

    delete req.name_de;
    delete req.name_it;
    delete req.name_fr;

    if (isNew) {
      const res = await db.from('company_additional_columns')
        .insert(req);

      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }
    } else {
      const res = await db.from('company_additional_columns')
        .update(req)
        .eq('id', id);
      if (res.error) {
        console.log(res.error);
        //setError(res.error.message);
        //return;
      }
    }

    const req2 = data.reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});
    const res = await db.from('translations')
      .insert({
        rs: req2.name,
        de: req2.name_de,
        fr: req2.name_fr,
        it: req2.name_it,
      });
    if (res.error) {
      console.log(res.error);
      const res2 = await db.from('translations')
        .update({
          de: req2.name_de,
          fr: req2.name_fr,
          it: req2.name_it,
        })
        .eq('rs', req2.name);
      console.log(res2);
    }

    navigate(-1);
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  return (
    <Layout title="Porezi">
      <CCard back={() => navigate(-1)}>
        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />

        <MessageDialog
          open={!!error}
          title="Greška"
          text={error}
          onClose={() => setError(null)} />
      </CCard>
    </Layout>
  );
}

export default CompanyAddDataEdit;
