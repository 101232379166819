import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { getDb } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';
import ConfirmDialog from '../components/ConfirmDialog';

const columns = [
  {
    id: 'name',
    title: 'Naziv',
  },
  {
    id: 'actions',
    title: '',
  },
]


const CompanyAddData = (props) => {
  const navigate = useNavigate();

  const [removeId, setRemoveId] = useState(null);

  const [values, setValues] = useState([]);

  const [search, setSearch] = useState('');
  const searchTimer = useRef(null);

  const fetchData = async (txt = '') => {
    const db = getDb();

    const res = await db.from('company_additional_columns')
      .select()
      .ilike('name', `%${txt}%`);

    if (res.error) {
      console.log(res.error);
      return;
    }

    setValues(res.data);
  }

  const deleteData = async (id) => {
    const db = getDb();
    const res = await db.from('company_additional_columns')
      .delete()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
    }

    setValues(values.filter(el => el.id !== id));
  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));
  }, []);

  const onEditHandler = (id, title = null) => {
    navigate('/company_add_data/' + id + '?title=' + title);
  }

  const onDeleteHandler = () => {
    deleteData(removeId)
      .catch(err => console.log(err));
    setRemoveId(null);
  }

  const onSearchUpdate = (newSearch) => {
    setSearch(newSearch);

    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      fetchData(newSearch);
    }, 500);
  }

  let removeDialog = null;
  if (removeId) {
    removeDialog = (
      <ConfirmDialog
        title="Brisanje polja"
        text="Da li ste sigurni da želite obrisati polje"
        open={!!removeId}
        onConfirm={onDeleteHandler}
        onCancel={() => setRemoveId(null)}
        payload={removeId}
        danger
      />
    );
  }

  const staticData = [
    'Naziv',
    'Poreski identifikacioni broj',
    'Adresa',
    'Mesto',
    'Telefon',
    'Plata opis',
  ].map(el => {
    return {
      id: -1,
      name: el,
      actions: (
        <IconButton onClick={() => onEditHandler(-1, el)}>
          <EditIcon />
        </IconButton>
      ),
    }
  });

  const dynamicData = values.map(el => {
    const res = {
      ...el,
      actions: (
        <>
          <IconButton onClick={() => onEditHandler(el.id)}>
            <EditIcon />
          </IconButton>
          &nbsp;
          <IconButton color="error" onClick={() => setRemoveId(el.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    };

    return res;
  });

  const data = [
    ...staticData,
    ...dynamicData,
  ]

  return (
    <Layout title="Porezi">
      <CCard>
        <div>
          <Button variant="contained" onClick={() => onEditHandler('new')}>Novo polje</Button>
        </div>
        <br />
        <br />
        <div>
          <TextField
            fullWidth
            label="Pretraga"
            variant="standard"
            value={search}
            onChange={e => onSearchUpdate(e.target.value)} />
        </div>
        <br />
        <GenericTable
          columns={columns}
          data={data} />
      </CCard>

      {removeDialog}
    </Layout>
  );
}

export default CompanyAddData;
