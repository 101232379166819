import React from 'react';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './pages/Home';
import Translations from './pages/Translations';
import Taxes from './pages/Taxes';
import TaxEdit from './pages/TaxEdit';
import CompanyAddData from './pages/CompanyAddData';
import CompanyAddDataEdit from './pages/CompanyAddDataEdit';

const App = () => {
  const router = createHashRouter([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/translations',
      element: <Translations />,
    },

    {
      path: '/taxes/new',
      element: <TaxEdit />,
    },
    {
      path: '/taxes/:id',
      element: <TaxEdit />,
    },
    {
      path: '/taxes',
      element: <Taxes />,
    },

    {
      path: '/company_add_data/new',
      element: <CompanyAddDataEdit />,
    },
    {
      path: '/company_add_data/:id',
      element: <CompanyAddDataEdit />,
    },
    {
      path: '/company_add_data',
      element: <CompanyAddData />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
