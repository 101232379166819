import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getDb } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';

const initialFrm = [
  {
    id: 'journal_code',
    type: 'number',
    label: 'Broj u zurnalu',
    value: '',
    valication: {
    },
  },
  {
    id: 'group',
    type: 'select',
    label: 'Grupa',
    value: 'salary',
    options: [
      {
        value: 'salary',
        title: 'Plata',
      },
      {
        value: 'tax',
        title: 'Odbici',
      },
      {
        value: 'other',
        title: 'Ostalo',
      },
    ],
    valication: {
    },
  },
  {
    id: 'category',
    type: 'select',
    label: 'Kategorija',
    value: 'default',
    options: [
      {
        value: 'default',
        title: 'Podrazumevano',
      },
      {
        value: 'optional',
        title: 'Opciono',
      },
      {
        value: 'ahv',
        title: 'AHV',
      },
      {
        value: 'qs',
        title: 'QS',
      },
      {
        value: 'bvg',
        title: 'BVG',
      },
      {
        value: 'nbu',
        title: 'NBU',
      },
      {
        value: 'other',
        title: 'Ostali odbici',
      },
      {
        value: 'rounding',
        title: 'Rounding',
      },
    ],
    valication: {
    },
  },
  {
    id: 'type',
    type: 'select',
    label: 'Tip',
    value: 'v',
    options: [
      {
        value: 'v',
        title: 'Vrednost',
      },
      {
        value: 'p',
        title: 'Procenat - %',
      },
      {
        value: 'h',
        title: 'Sat - h',
      },
      {
        value: 'hd',
        title: 'Sat - h (da dodacima)',
      },
    ],
    valication: {
    },
  },
  {
    id: 'value',
    type: 'number',
    label: 'Vrednost',
    value: '',
    valication: {
    },
  },
  {
    id: 'base',
    type: 'number',
    label: 'Osnovica',
    value: '',
    valication: {
    },
  },
  {
    id: 'total',
    type: 'number',
    label: 'Ukupno',
    value: '',
    valication: {
    },
  },
  {
    id: 'description_de',
    type: 'text',
    label: 'Naziv DE',
    value: '',
    valication: {
    },
  },
  {
    id: 'description_fr',
    type: 'text',
    label: 'Naziv FR',
    value: '',
    valication: {
    },
  },
  {
    id: 'description_it',
    type: 'text',
    label: 'Naziv IT',
    value: '',
    valication: {
    },
  },
  {
    id: 'base_type',
    type: 'select',
    label: 'Tip osnovice',
    value: 'salary',
    options: [
      {
        value: 'salary',
        title: 'Ukupni prihodi',
      },
      {
        value: 'salary_h',
        title: 'Plata - Sat (h)',
      },
      {
        value: 'salary_v',
        title: 'Plata - Fiksna',
      },
      {
        value: 'salary_h_v',
        title: 'Plata - Fiksna + Sat (h)',
      },
    ],
    valication: {
    },
  },
  {
    id: 'qs_base',
    type: 'switch',
    label: 'QS Osnovica',
    value: false,
    valication: {
    },
  },
  {
    id: 'not_ahv',
    type: 'switch',
    label: 'Ne ulazi u AHV osnovicu',
    value: false,
    valication: {
    },
  },
  {
    id: 'not_nbu',
    type: 'switch',
    label: 'Ne ulazi u NBU osnovicu',
    value: false,
    valication: {
    },
  },
  {
    id: 'not_other',
    type: 'switch',
    label: 'Ne ulazi u ostale odbitke',
    value: false,
    valication: {
    },
  },
  {
    id: 'has_description',
    type: 'switch',
    label: 'Ima opis',
    value: false,
    valication: {
    },
  },
  {
    id: 'user_can_change',
    type: 'switch',
    label: 'Individualno za firmu',
    value: false,
    valication: {
    },
  },
  {
    id: 'employee_can_change',
    type: 'switch',
    label: 'Individualno za zaposlenog',
    value: false,
    valication: {
    },
  },
]

const TaxEdit = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isNew = id ? false : true;

  const [frm, setFrm] = useState(initialFrm);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const db = getDb();
    const res = await db.from('taxes')
      .select()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
      navigate('/departments');
    }

    if (res.data && res.data.length) {
      const updatedFrm = frm.map(el => {
        return {
          ...el,
          value: res.data[0][el.id],
        }
      });
      setFrm(updatedFrm);
    }
  }

  useEffect(() => {
    if (id) {
      fetchData().catch(err => console.log(err));
    }
  }, [id]);

  const onSaveHandler = async (data) => {
    const db = getDb();

    const req = data.reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});

    if (isNew) {
      const res = await db.from('taxes')
        .insert(req);

      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }
    } else {
      const res = await db.from('taxes')
        .update(req)
        .eq('id', id);
      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }
    }

    navigate(-1);
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  return (
    <Layout title="Porezi">
      <CCard back={() => navigate(-1)}>
        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />

        <MessageDialog
          open={!!error}
          title="Greška"
          text={error}
          onClose={() => setError(null)} />
      </CCard>
    </Layout>
  );
}

export default TaxEdit;
